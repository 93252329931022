import * as React from "react"
import Header from "./header"

const Layout = ({ location, title, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  const isRootPath = location.pathname === rootPath

  return (
    <div data-is-root-path={isRootPath} id="outer-container">
      <Header location={location} />
      <main>{children}</main>
      <footer className="global-footer">
        © {new Date().getFullYear()} {title}
      </footer>
    </div>
  )
}

export default Layout
